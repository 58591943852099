<template>
  <NavbarPart />
    <section
      class="header relative pt-10 items-center flex h-screen max-h-860-px"
    >
      <div class="container mx-auto items-center flex flex-wrap">
        <div class="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
          <div class="pt-32 sm:pt-0">
            <h2 class="font-semibold text-4xl text-blueGray-600">
              TitikBaca Digital
            </h2>
            <p class="mt-4 text-lg leading-relaxed text-blueGray-500">
              Aplikasi <strong>TitikBaca Digital</strong> memungkinkan penggunanya untuk membiasakan diri meluangkan waktu dengan membaca ditempat umum. <br>
              Pengguna juga dapat membuat <strong>catatan</strong>, dan menyimpan catatan, seperti kegunaan <strong>notes.</strong><br>
              Berbagai pilihan <strong>kategori buku</strong> bisa disesuaikan dengan minatmu. Melalui kategori yang dipilih akan menjadi pilihan bacaan paling <strong>Direkomendasikan</strong> untukmu. <br>
              Selain fitur buku dan membaca buku, pengguna juga dapat <strong>menyimpan</strong> bacaan,agar dapat <strong>melanjutkan</strong> bacaan dilain waktu.
            </p>
            <div class="mt-12">
              <a
                href="https://play.google.com/store/apps/details?id=com.titikbacadigital"
                style="text-decoration: none; color: inherit"
              >
                <button
                  class="btn btn-ghost capitalize rounded-full bg-[#00BB8D] text-white px-8 shadow-lg shadow-slate-700/40"
                >
                  <span><img class="m-0" src="../assets/icons/google-play.svg" /></span>
                  <span class="inline-block text-lg ml-3 font-semibold">Download Sekarang</span>
                </button>
              </a>
              <!-- <a
                href="https://www.creative-tim.com/learning-lab/tailwind/vue/overview/notus?ref=vn-index"
                target="_blank"
                class="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-emerald-500 active:bg-emerald-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
              >
                Get started
              </a>
              <a
                href="https://github.com/creativetimofficial/vue-notus?ref=vn-index"
                class="github-star ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                target="_blank"
              >
                Github Star
              </a> -->
            </div>
          </div>
        </div>
      </div>

      <img
        class="absolute top-0 b-auto right-0 pt-10 sm:w-6/12 -mt-48 sm:mt-0 w-10/12 max-h-860-px invisible sm:visible"
        :src="patternVue"
        alt="..."
      />
    </section>

    <section class="mt-48 md:mt-40 pb-20 relative bg-blueGray-100">
      <div
        class="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
        style="transform: translateZ(0);"
      >
        <svg
          class="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            class="text-blueGray-100 fill-current"
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <!-- <div class="container ml-auto px-5 py-10">
        <div class="flex flex-wrap items-center">
          <img
            alt="..."
            src="@/assets/img/slide/1.png"
            class="w-full align-middle rounded-t-lg rounded-b-lg"
          />
        </div>
      </div>
      <div class="container mr-auto px-5 py-5">
        <div class="flex flex-wrap items-center">
          <img
            alt="..."
            src="@/assets/img/slide/2.png"
            class="w-full align-middle rounded-t-lg rounded-b-lg"
          />
        </div>
      </div>
      <div class="container ml-auto px-5 py-5">
        <div class="flex flex-wrap items-center">
          <img
            alt="..."
            src="@/assets/img/slide/3.png"
            class="w-full align-middle rounded-t-lg rounded-b-lg"
          />
        </div>
      </div>
      <div class="container mr-auto px-5 py-5">
        <div class="flex flex-wrap items-center">
          <img
            alt="..."
            src="@/assets/img/slide/4.png"
            class="w-full align-middle rounded-t-lg rounded-b-lg"
          />
        </div>
      </div>
      <div class="container ml-auto px-5 py-5">
        <div class="flex flex-wrap items-center">
          <img
            alt="..."
            src="@/assets/img/slide/5.png"
            class="w-full align-middle rounded-t-lg rounded-b-lg"
          />
        </div>
      </div>
      <div class="container mr-auto px-5 py-5">
        <div class="flex flex-wrap items-center">
          <img
            alt="..."
            src="@/assets/img/slide/6.png"
            class="w-full align-middle rounded-t-lg rounded-b-lg"
          />
        </div>
      </div>
      <div class="container ml-auto px-5 py-5">
        <div class="flex flex-wrap items-center">
          <img
            alt="..."
            src="@/assets/img/slide/7.png"
            class="w-full align-middle rounded-t-lg rounded-b-lg"
          />
        </div>
      </div>
      <div class="container mr-auto px-5 py-5">
        <div class="flex flex-wrap items-center">
          <img
            alt="..."
            src="@/assets/img/slide/8.png"
            class="w-full align-middle rounded-t-lg rounded-b-lg"
          />
        </div>
      </div>
      <div class="container ml-auto px-5 py-5">
        <div class="flex flex-wrap items-center">
          <img
            alt="..."
            src="@/assets/img/slide/9.png"
            class="w-full align-middle rounded-t-lg rounded-b-lg"
          />
        </div>
      </div>
      <div class="container mr-auto px-5 py-5">
        <div class="flex flex-wrap items-center">
          <img
            alt="..."
            src="@/assets/img/slide/10.png"
            class="w-full align-middle rounded-t-lg rounded-b-lg"
          />
        </div>
      </div> -->

      <!-- <div class="container w-full w-full max-w-full mt-10">
        <section id="call-to-action" class="prose w-full max-w-full">
          <div class="carousel w-full max-h-full">
            <div id="item1" class="py-10 carousel-item w-full bg-banner">
              <div class="container w-full max-h-full mx-auto text-center prose py-20">
                <h2 class="text-white mb-8 px-20 sm:px-32 md:px-32 lg:px-32 xl:px-32 2xl:px-32 leading-relaxed">
                  Belum punya aplikasi TitikBaca? Yuk, unduh sekarang juga.
                </h2>
                <a
                  href="https://play.google.com/store/apps/details?id=com.spodbacadigital"
                  style="text-decoration: none; color: inherit"
                >
                  <button
                    class="btn btn-ghost capitalize rounded-full bg-[#00BB8D] text-white px-8 shadow-lg shadow-slate-700/40"
                  >
                    <span><img class="m-0" src="../assets/icons/google-play.svg" /></span>
                    <span class="inline-block text-lg ml-3 font-semibold">Download Sekarang</span>
                  </button>
                </a>
              </div>
            </div>
          </div>
        </section>
      </div> -->
    </section>
  <CarouselPart  />
  <ContentPart />
  <FooterPart />
</template>

<script>
import NavbarPart from '../components/Navbar.vue'
import CarouselPart from '../components/Carousel.vue'
import ContentPart from '../components/Content.vue'
import patternVue from "@/assets/img/pattern_vue.png";
import componentBtn from "@/assets/img/component-btn.png";
import componentProfileCard from "@/assets/img/component-profile-card.png";
import componentInfoCard from "@/assets/img/component-info-card.png";
import componentInfo2 from "@/assets/img/component-info-2.png";
import componentMenu from "@/assets/img/component-menu.png";
import componentBtnPink from "@/assets/img/component-btn-pink.png";
import documentation from "@/assets/img/documentation.png";
import login from "@/assets/img/login.jpg";
import profile from "@/assets/img/profile.jpg";
import landing from "@/assets/img/landing.jpg";
import FooterPart from '../components/Footer.vue'
import "@/assets/styles/tailwind.css";

// import { onMounted, ref } from 'vue'
// import { useRoute, useRouter } from 'vue-router'


export default {
  name: 'App',
  components: {
    NavbarPart,
    CarouselPart,
    ContentPart,
    FooterPart,
  },
  data() {
    return {
      patternVue,
      componentBtn,
      componentProfileCard,
      componentInfoCard,
      componentInfo2,
      componentMenu,
      componentBtnPink,
      documentation,
      login,
      profile,
      landing,
    }
  },
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
#app {
  font-family: 'Open Sans', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}
html {
  scroll-behavior: smooth;
}
.bg-banner {
  background-image: url('../assets/images/properties/banner.png');
  object-fit: contain;
  object-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
