<template>
  <div>
    <div class="bg-footer">
      <footer class="container mx-auto px-5">
        <div class="pt-6">
          <img src="../assets/images/logos/footer-logo.svg" class="min-w-10 w-56" />
        </div>
        <div class="divider"></div>
        <div
          class="flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row items-start md:items-center lg:items-center xl:items-center 2xl:items-center"
        >
          <div class="flex w-full">
            <span class="text-white hidden md:block lg:block xl:block 2xl:block mb-2 mr-auto text-sm"
              >© TitikBaca DIgital 2022
            </span>
          </div>
          <div class="flex w-full text-white justify-end">
            <div
              class="prose flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row justify-between items-end md:items-center lg:items-center xl:items-center 2xl:items-center"
            >
              <router-link
                to="/privacy-policy"
                class="text-white block link link-hover mb-3 ml-auto text-base md:text-sm lg:text-sm xl:text-sm 2xl:text-sm"
                >Kebijakan Privasi</router-link>
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterPart',
  data() {
    return {}
  },
  methods: {},
}
</script>

<style scoped>
.bg-footer {
  background: rgb(4, 130, 117);
  background: linear-gradient(156deg, rgba(4, 130, 117, 1) 0%, rgba(26, 16, 78, 1) 100%);
}

.divider:before,
.divider:after {
  background-color: #ffffff69 !important;
  opacity: 0.8 !important;
  height: 0.02rem !important;
}
</style>
