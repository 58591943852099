<template>
  <div id="info">
    <div class="container mx-auto px-5 w-full" v-if="company">
      <section id="information" class="prose w-full max-w-full mb-10">
        <div v-for="layout in company.layouts" :key="layout.uuid">
          <div v-if="layout.type === 'onlytext'">
            <h2 class="text-black uppercase">{{ layout.title }}</h2>
            <p class="m-0 text-black">
              {{ layout.description }}
            </p>
          </div>
          <div v-if="layout.type === 'onlypict'">
            <h2 class="text-black uppercase">{{ layout.title }}</h2>
            <div v-if="layout.galleries.length < 2" class="grid grid-cols-1 gap-1 mt-10">
              <div v-for="gallery in layout.galleries" :key="gallery.uuid">
                <img class="mx-auto my-0 object-center object-cover h-full w-4/5" :src="gallery.image" />
              </div>
            </div>
            <div v-if="layout.galleries.length == 2" class="grid grid-cols-2 gap-2 mt-10">
              <div v-for="gallery in layout.galleries" :key="gallery.uuid">
                <img class="m-0 object-cover h-full w-full" :src="gallery.image" />
              </div>
            </div>
            <div v-if="layout.galleries.length == 3" class="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-3 mt-10">
              <div v-for="gallery in layout.galleries" :key="gallery.uuid">
                <img class="m-0 object-cover h-full w-full" :src="gallery.image" />
              </div>
            </div>
            <div v-if="layout.galleries.length > 3" class="grid grid-cols-2 md:grid-cols-4 gap-4 mt-10">
              <div v-for="gallery in layout.galleries" :key="gallery.uuid">
                <img class="m-0 object-cover h-56 w-full" :src="gallery.image" />
              </div>
            </div>
          </div>
          <div v-if="layout.type === 'textwithpict'">
            <h2 class="text-black uppercase">{{ layout.title }}</h2>
            <p class="m-0 text-black">
              {{ layout.description }}
            </p>
            <div v-if="layout.galleries.length < 2" class="grid grid-cols-1 gap-1 mt-10">
              <div v-for="gallery in layout.galleries" :key="gallery.uuid">
                <img class="mx-auto my-0 object-center object-cover h-full w-4/5" :src="gallery.image" />
              </div>
            </div>
            <div v-if="layout.galleries.length == 2" class="grid grid-cols-2 gap-2 mt-10">
              <div v-for="gallery in layout.galleries" :key="gallery.uuid">
                <img class="m-0 object-cover h-full w-full" :src="gallery.image" />
              </div>
            </div>
            <div v-if="layout.galleries.length == 3" class="grid grid-cols-2 md:grid-cols-3 gap-2 md:gap-3 mt-10">
              <div v-for="gallery in layout.galleries" :key="gallery.uuid">
                <img class="m-0 object-cover h-full w-full" :src="gallery.image" />
              </div>
            </div>
            <div v-if="layout.galleries.length > 3" class="grid grid-cols-2 md:grid-cols-4 gap-4 mt-10">
              <div v-for="gallery in layout.galleries" :key="gallery.uuid">
                <img class="m-0 object-cover h-56 w-full" :src="gallery.image" />
              </div>
            </div>
          </div>
          <div v-if="layout.type === 'slider'">
            <h2 class="text-black uppercase">{{ layout.title }}</h2>
            <swiper
              :slides-per-view="1"
              :navigation="true"
              :modules="modules"
              :spaceBetween="10"
              :loop="true"
              :pagination="{ clickable: true }"
              :breakpoints="{
                '@0.00': {
                  slidesPerView: 1,
                  spaceBetween: 10,
                },
                '@1.00': {
                  slidesPerView: 2,
                  spaceBetween: 10,
                },
                '@1.50': {
                  slidesPerView: 3,
                  spaceBetween: 10,
                },
              }"
              class="mySwiper"
            >
              <swiper-slide v-for="gallery in layout.galleries" :key="gallery.uuid">
                <img :src="gallery.image" class="m-0 w-full object-center object-cover" />
              </swiper-slide>
            </swiper>
            <!-- <div class="carousel w-full h-96 rounded-lg relative">
                <div
                  v-for="(gallery, index) in layout.galleries"
                  :key="gallery.uuid"
                  :id="'slide' + (index + 1)"
                  class="carousel-item relative w-full"
                >
                  <img :src="gallery.image" class="m-0 w-full object-center object-cover" />
                </div>
                <div class="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
                  <a @click="prevSlide" class="btn btn-circle">❮</a>
                  <a @click="nextSlide" class="btn btn-circle">❯</a>
                </div>
              </div>
              <div class="flex justify-center w-full py-2 gap-2 mt-4">
                <a href="#slide1" role="button" class="rounded-full d-inline-block bg-slate-300 w-3 h-3"></a>
                <a href="#slide2" role="button" class="rounded-full d-inline-block bg-slate-300 w-3 h-3"></a>
                <a href="#slide3" role="button" class="rounded-full d-inline-block bg-slate-300 w-3 h-3"></a>
                <a href="#slide4" role="button" class="rounded-full d-inline-block bg-slate-300 w-3 h-3"></a>
              </div> -->
          </div>
        </div>
      </section>
      <section id="location" class="prose w-full max-w-full mb-10 mt-14">
        <div class="flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row items-center">
          <div class="map-responsive flex-none w-full md:w-2/5 lg:w-2/5 xl:w-2/5 2xl:w-2/5 py-14">
            <iframe
              :src="url"
              width="650"
              height="400"
              allowfullscreen
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
          <div
            class="flex-none w-full md:w-3/5 lg:w-3/5 xl:w-3/5 2xl:w-3/5 items-center prose ml-14 md:ml-24 lg:ml-24 xl:ml-24 2xl:ml-24 mt-10 md:mt-0 lg:mt-0 xl:mt-0 2xl:mt-0"
          >
            <h6 class="text-emerald-600 uppercase mb-2">{{ company.name }}</h6>
            <h2 class="text-black my-2">Lokasi</h2>
            <div class="relative">
              <p class="m-0 text-black">
                {{ company.address }}
              </p>
              <div class="absolute top-1.5 -left-7">
                <img class="m-0 w-9/12" src="../assets/icons/location.svg" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="statistic" class="prose w-full max-w-full mb-10">
        <div class="flex flex-col sm:flex-row md:flex-row lg:flex-row xl:flex-row 2xl:flex-row items-center">
          <div class="my-5 flex-none w-full sm:w-2/4 md:w-2/4 prose px-10 text-center m-auto">
            <div class="w-[218px] h-[218px] py-[70px] bg-transparent mx-auto rounded-full border-8 border-[#EC008C]">
              <h1 class="text-4xl text-[#EC008C] font-bold m-0">{{ company.total_visitor }}+</h1>
              <span class="text-black text-lg">Kunjungan</span>
            </div>
          </div>
          <div class="my-5 flex-none w-full sm:w-2/4 md:w-2/4 prose px-10 text-center m-auto">
            <div class="w-[218px] h-[218px] py-[70px] bg-transparent mx-auto rounded-full border-8 border-[#FCA311]">
              <h1 class="text-4xl text-[#FCA311] font-bold m-0">{{ company.total_library }}+</h1>
              <span class="text-black text-lg">E-Book</span>
            </div>
          </div>
        </div>
      </section>
    </div>
    <div class="container w-full w-full max-w-full">
      <section id="call-to-action" class="prose w-full max-w-full mb-24">
        <div class="carousel w-full max-h-full">
          <div id="item1" class="py-10 carousel-item w-full bg-banner">
            <div class="container w-full max-h-full mx-auto text-center prose py-20">
              <h2 class="text-white mb-8 px-20 sm:px-32 md:px-32 lg:px-32 xl:px-32 2xl:px-32 leading-relaxed">
                Belum punya aplikasi TitikBaca? Yuk, unduh sekarang juga.
              </h2>
              <a
                href="https://play.google.com/store/apps/details?id=com.titikbacadigital"
                style="text-decoration: none; color: inherit"
              >
                <button
                  class="btn btn-ghost capitalize rounded-full bg-[#00BB8D] text-white px-8 shadow-lg shadow-slate-700/40"
                >
                  <span><img class="m-0" src="../assets/icons/google-play.svg" /></span>
                  <span class="inline-block text-lg ml-3 font-semibold">Download Sekarang</span>
                </button>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper'
import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

export default {
  props: {
    company: {
      type: Object,
      default: () => {},
    },
  },
  name: 'ContentPart',
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      modules: [Navigation, Pagination],
      url: '',
      settings: {
        dots: true,
        infinite: true,
        rows: 1,
        initialSlide: 2,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipeToSlide: true,
        arrows: true,
      },
      galleries: [
        {
          title: '1',
        },
        {
          title: '2',
        },
        {
          title: '3',
        },
        {
          title: '4',
        },
        {
          title: '5',
        },
        {
          title: '6',
        },
        {
          title: '7',
        },
        {
          title: '8',
        },
        {
          title: '9',
        },
        {
          title: '10',
        },
        {
          title: '11',
        },
        {
          title: '12',
        },
      ],
    }
  },
  watch: {
    company: {
      handler() {
        this.url = `https://maps.google.com/maps?q=${this.company.latitude},${this.company.longitude}&hl=es;z=14&output=embed`
      },
    },
  },
  mounted() {
    this.getSlideCount = document.querySelectorAll('.carousel').length
  },
  methods: {
    nextSlide() {
      if (this.currentSlide === this.getSlideCount) {
        this.currentSlide = 1
        return
      }
      this.currentSlide += 1
    },
    prevSlide() {
      if (this.currentSlide === 1) {
        this.currentSlide = 1
        return
      }
      this.currentSlide -= 1
    },
  },
}
</script>

<style scoped>
.map-responsive {
  overflow: hidden;
  padding-bottom: 30%;
  position: relative;
  height: 0;
}

.map-responsive iframe {
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

.bg-banner {
  background-image: url('../assets/images/properties/banner.png');
  object-fit: contain;
  object-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.circle {
  width: 40px;
  height: 40px;
}

.swiper {
  width: 100%;
  height: 24rem;
  border-radius: 0.5rem;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
}

div::v-deep > .swiper > .swiper-button-next:after {
  content: '❯' !important;
}

div::v-deep > .swiper > .swiper-button-prev:after {
  content: '❮' !important;
}

div::v-deep > .swiper > .swiper-button-next:after,
div::v-deep > .swiper > .swiper-button-prev:after {
  font-family: none !important;
  color: #ffffff;
}
</style>
